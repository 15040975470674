import { useIsMobile } from '@app/hooks/useIsMobile';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  SvgIconTypeMap,
  Typography,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const variants = {
  sub: {
    light: '#FCF3F4',
    mid: '#E5A4A4',
    dark: '#911F27',
  },
  total: {
    light: '#F4FCF3',
    mid: '#B9E5A4',
    dark: '#28911F',
  },
};

interface Props {
  title: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  variant: 'sub' | 'total';
  points?: number;
}

export function PointSummaryCard({ title, Icon, variant, points }: Props) {
  const isMobile = useIsMobile();
  const colors = variants[variant];

  return (
    <Card sx={{ bgcolor: colors.light, height: 1 }}>
      <CardContent sx={{ px: 2, py: 2 }}>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box sx={{ order: isMobile ? 2 : 1 }}>
            <Typography variant="h6">{title}</Typography>
            <Stack
              direction="row"
              alignItems="end"
              justifyContent={isMobile ? 'center' : 'end'}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ color: colors.dark, mr: 1 }}
              >
                {points ?? <CircularProgress size="1.5rem" />}
              </Typography>
              <Typography sx={{ color: colors.mid }}>Points</Typography>
            </Stack>
          </Box>
          <Card
            elevation={0}
            sx={{ p: 1, bgcolor: `${colors.mid}6B`, color: colors.dark }}
          >
            <Stack justifyContent="center" alignItems="center">
              <Icon />
            </Stack>
          </Card>
        </Stack>
      </CardContent>
    </Card>
  );
}
