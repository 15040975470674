import {
  Avatar,
  Card,
  CardContent,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
  Box,
  Button,
} from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useIsMobile } from '@app/hooks/useIsMobile';
import { LecturerData } from '@app/services/lecturerService';

interface Props {
  lecturer: LecturerData;
}

export function LecturerDetailsCard(props: Props) {
  const { lecturer } = props;
  const isMobile = useIsMobile();

  const keyValueRenderer = (key: string, value: string | JSX.Element) => (
    <>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1" fontWeight="bold">
          {key}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} mb={2}>
        <Stack direction="row">
          {isMobile ? (
            ''
          ) : (
            <Typography variant="body1" fontWeight="bold" marginRight={2}>
              :
            </Typography>
          )}
          <Typography>{value}</Typography>
        </Stack>
      </Grid>
    </>
  );

  const name = `${lecturer.title}. ${lecturer.user.firstName} ${lecturer.user.lastName}`;

  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container spacing={1} padding={2}>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" flexWrap="wrap">
              <Typography variant="h6" fontWeight="bold" mr={2}>
                {name}
              </Typography>
              <Stack direction="row" alignItems="center">
                <IconButton aria-label="email" disableRipple>
                  <Link target="_blank" href={`mailto:${lecturer.user.email}`}>
                    <MailIcon
                      color="primary"
                      fontSize="medium"
                      sx={{ mt: 0.75 }}
                    />
                  </Link>
                </IconButton>
                {lecturer.isAdvisor &&
                  (lecturer.advisorUrl ? (
                    <Link
                      href={lecturer.advisorUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ textDecoration: 'none' }}
                    >
                      <Button
                        variant="contained"
                        color="warning"
                        size="small"
                        disableElevation
                        sx={{
                          textTransform: 'none',
                          py: 0,
                          px: 1,
                        }}
                      >
                        Advisor{' '}
                        <OpenInNewIcon sx={{ fontSize: '.9rem', ml: 1 }} />
                      </Button>
                    </Link>
                  ) : (
                    <Button
                      variant="contained"
                      color="warning"
                      size="small"
                      disableElevation
                      sx={{
                        textTransform: 'none',
                        py: 0,
                        px: 1,
                      }}
                    >
                      Advisor
                    </Button>
                  ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              alignItems="center"
              spacing={{ xs: 2, md: 4 }}
            >
              <Avatar
                alt="lecturer-avatar"
                src={lecturer.user.profilePicture}
                sx={{ width: isMobile ? 80 : 150, height: isMobile ? 80 : 150 }}
              >
                {lecturer.user.firstName.charAt(0)}
              </Avatar>
              <Stack direction="column">
                <Typography
                  variant={isMobile ? 'body2' : 'body1'}
                  color="primary"
                  fontWeight="bold"
                >
                  {`"${lecturer.quote}"`}
                </Typography>
                <Typography
                  variant={isMobile ? 'body2' : 'body1'}
                  color="primary"
                  textAlign="right"
                >
                  {`- ${name}`}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          {lecturer.favouriteBook &&
            keyValueRenderer(
              'Favourite Book',
              lecturer.favouriteBookUrl ? (
                <Link
                  href={lecturer.favouriteBookUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {lecturer.favouriteBook}{' '}
                  <OpenInNewIcon sx={{ fontSize: '1rem' }} />
                </Link>
              ) : (
                lecturer.favouriteBook
              ),
            )}
          {lecturer.hobbies &&
            keyValueRenderer('Favourite Hobbies', lecturer.hobbies)}
          {lecturer.favouriteFood &&
            keyValueRenderer('Favourite Foods', lecturer.favouriteFood)}
          {(lecturer.hometownDesc || lecturer.hometown) &&
            keyValueRenderer(
              'Home Town',
              lecturer.hometownDesc || lecturer.hometown,
            )}
          {lecturer.funFacts && keyValueRenderer('Fun Fact', lecturer.funFacts)}
          {lecturer.images.length > 0 && (
            <>
              <Grid item xs={12} sm={12}>
                <Typography variant="body1" fontWeight="bold">
                  Gallery
                </Typography>
              </Grid>
              {lecturer.images.map(item => (
                <Grid item xs={12} lg={6} p={1}>
                  <Box
                    component="img"
                    src={item.url}
                    alt={item.caption}
                    loading="lazy"
                    sx={{
                      maxHeight: 150,
                      width: 1,
                      objectFit: 'contain',
                    }}
                  />
                  <Typography
                    variant="body2"
                    lineHeight={1}
                    fontSize=".8rem"
                    align="center"
                  >
                    {item.caption}
                  </Typography>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
