import { DataWrapper } from '@app/components/DataWrapper';
import { ResourceCard } from '@app/components/ResourceCard';
import {
  ContentData,
  getFinacilaLiteracy,
} from '@app/services/resourceService';
import { Grid } from '@mui/material';

export function ListView() {
  return (
    <DataWrapper<ContentData>
      id="lecturer-list"
      title="Financial Literacy"
      filterConfig={[]}
      fetchData={({ token, options }) =>
        getFinacilaLiteracy({ token, options })
      }
      render={data => (
        <Grid container spacing={4} px={{ xs: 2, lg: 4 }}>
          {data.map(x => (
            <Grid item xs={12} md={12} lg={6} xl={4} key={x.id}>
              <ResourceCard
                title={x.title}
                description={x.description}
                image={x.image}
                url={x.url}
                youtubeUrl={x.youtubeUrl}
                btnText={x.btnText}
              />
            </Grid>
          ))}
        </Grid>
      )}
    />
  );
}
