import { PointsCard } from '@app/components/PointsCard';
import { useIsMobile } from '@app/hooks/useIsMobile';
import { useProfile } from '@app/hooks/useProfile';
import { Avatar, Badge, Box, Stack, Typography } from '@mui/material';
import { ChangeProfileImage } from './ChangeProfileImage';

export function UserOverview() {
  const isMobile = useIsMobile();
  const contentP = isMobile ? 0 : 4;
  const avatarSize = isMobile ? 120 : 164;
  const textAlign = isMobile ? 'center' : 'left';
  const pointsCardWidth = isMobile ? '100vw' : 220;
  const pointsCardMx = isMobile ? -4 : 0;

  const { data } = useProfile();

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems="center"
      spacing={{ xs: 2, sm: 4 }}
      mb={4}
      p={contentP}
    >
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={<ChangeProfileImage />}
      >
        <Avatar
          src={data?.profilePicture}
          alt={data?.displayName}
          sx={{ height: avatarSize, width: avatarSize }}
        />
      </Badge>

      <Box width={1}>
        <Typography variant="h6" fontWeight="bold" align={textAlign}>
          {data?.displayName}
        </Typography>
        <Typography variant="body2" color="gray" align={textAlign} mb={2}>
          {data?.email}
        </Typography>
        <Box mx={pointsCardMx} width={pointsCardWidth}>
          <PointsCard noBg={!isMobile} noAction />
        </Box>
      </Box>
    </Stack>
  );
}
