import { DataWrapper } from '@app/components/DataWrapper';
import { getLecturers, LecturerData } from '@app/services/lecturerService';
import { Grid } from '@mui/material';
import { LecturerListCard } from './LecturerListCard';

export function LectureListView() {
  return (
    <DataWrapper<LecturerData>
      id="lecturer-list"
      title="Meet Your Professors"
      filterConfig={[]}
      fetchData={({ token }) => getLecturers(token)}
      render={data => (
        <Grid container spacing={2} px={{ xs: 2, lg: 4 }}>
          {data.map(x => (
            <Grid item xs={12} md={12} lg={6} xl={4} key={x.id}>
              <LecturerListCard lecturer={x} />
            </Grid>
          ))}
        </Grid>
      )}
    />
  );
}
