import { Layout } from '@app/components/Layout';
import { getNavLinks } from '@app/components/Layout/NavLinks';
import { PointsCard } from '@app/components/PointsCard';
import { OnlineUsers } from '@app/components/OnlineUsers';
import { useIsMobile } from '@app/hooks/useIsMobile';
import { useProfile } from '@app/hooks/useProfile';
import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { QuotesCarousel } from '@app/components/Layout/QuotesCarousel';
import { useAuth } from '@app/hooks/useAuth';
import { useQuery } from 'react-query';
import { TopRankers } from '../../components/TopRankers/TopRankers';
import { HomeCard } from './HomeCard';

const quotes = [
  {
    quote:
      "Man's mind, once stretched by a new idea, never regains its original dimensions.",
    author: 'Oliver Wendell Holmes',
  },
  {
    quote: 'Change is the end result of all true learning.',
    author: 'Leo Buscaglia',
  },
  {
    quote:
      'Education is the passport to the future, for tomorrow belongs to those who prepare for it today.',
    author: 'Malcolm X',
  },
  {
    quote: 'The cure for boredom is curiosity. There is no cure for curiosity',
    author: 'Dorothy Parker',
  },
];

function Home() {
  const isMobile = useIsMobile();
  const { data } = useProfile();

  const { tokenData } = useAuth();
  const token = tokenData?.token || '';
  const { data: navLinks } = useQuery<Awaited<ReturnType<typeof getNavLinks>>>(
    'system-config',
    () => getNavLinks(token),
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Layout>
      {isMobile && (
        <>
          <Box mb={2} mt={-1} mr={-1}>
            <OnlineUsers />
          </Box>
          <Typography variant="h6" fontWeight="bold" color="primary" mb={1}>
            Hello {data?.displayName},
          </Typography>
          <Box mx={isMobile ? -2 : -4} mb={4}>
            <PointsCard />
          </Box>
          <Box mb={-2}>
            <TopRankers showMoreLink />
          </Box>
        </>
      )}
      <Grid container spacing={{ xs: 1, sm: 4 }}>
        {navLinks ? (
          navLinks.map(link =>
            link.showInHome && !link.hidden ? (
              <Grid item xs={4} sm={6} md={4} key={link.name}>
                <HomeCard
                  key={link.name}
                  title={link.name}
                  Icon={link.Icon}
                  link={link.link}
                  disabled={link.disabled}
                  description={link.description}
                  color={link.color}
                />
              </Grid>
            ) : undefined,
          )
        ) : (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: { xs: 100, lg: 680 }, width: 1 }}
          >
            <CircularProgress />
          </Stack>
        )}
      </Grid>
      <Box marginTop={4}>
        <QuotesCarousel quotes={quotes} />
      </Box>
    </Layout>
  );
}

export { Home };
