/* eslint-disable @typescript-eslint/no-floating-promises */
import { useProfile } from '@app/hooks/useProfile';
import { Edit } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

export function ProfileInfo() {
  const [error, setError] = useState<string>();
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState<string>('');

  const { data, updateProfile } = useProfile({
    onUpdateSuccess: () => {
      setEditing(false);
      setError('');
    },
    onUpdateError: (err: Error) => setError(err.message),
  });

  const startEditing = () => setEditing(true);
  const cancelEditing = () => {
    setEditing(false);
    setName(data?.displayName || '');
  };
  const onChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setName(event.target.value);
  };
  const onSave = () => {
    if (name) {
      updateProfile({ displayName: name });
    }
  };

  useEffect(() => {
    if (data) {
      setName(data.displayName);
    }
  }, [data]);

  return (
    <Card variant="outlined">
      <CardContent sx={{ p: 4 }}>
        <Typography variant="h6" fontWeight="bold" mb={2}>
          Profile Info
        </Typography>

        {data ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Display Name"
                value={name}
                onChange={onChange}
                required
                InputProps={{
                  readOnly: !editing,
                  endAdornment: !editing && (
                    <InputAdornment position="end">
                      <IconButton onClick={startEditing}>
                        <Edit />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                size="small"
                fullWidth
              />
            </Grid>
            {editing && (
              <>
                {error && (
                  <Grid item xs={12}>
                    <Typography variant="caption" color="error">
                      *{error}
                    </Typography>
                  </Grid>
                )}
                <Grid component={Stack} item xs={12} justifyContent="end">
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={cancelEditing}
                    sx={{ mr: 1 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={onSave}
                  >
                    Save
                  </Button>
                </Grid>
              </>
            )}
            <Grid item xs={12} mb={2} />

            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label="First Name"
                value={data.firstName}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label="Last Name"
                value={data.lastName}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label="ID"
                value={data.customId}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label="Email"
                value={data.email}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <Stack alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}
