import { useAuth } from '@app/hooks/useAuth';
import { useIsMobile } from '@app/hooks/useIsMobile';
import { getOnlineStudents, StudentData } from '@app/services/studentsService';
import {
  Avatar,
  AvatarGroup,
  ButtonBase,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useQuery } from 'react-query';
import { GenericModal, useModal } from '../GenericModal';

import { AllOnlineUsers } from './AllOnlineUsers';

function getDisplayText(data: StudentData[]): string {
  if (data.length === 0) {
    return 'No students online';
  }
  if (data.length === 1) {
    return `${data[0].displayName} is online`;
  }
  return `${data[0].displayName} and ${data.length} others are online`;
}

export function OnlineUsers() {
  const isMobile = useIsMobile();

  const { isOpen, closeModal, openModal } = useModal();

  const avatarSize = isMobile ? 28 : 36;
  const textVariant = isMobile ? 'caption' : 'body1';

  const { tokenData } = useAuth();
  const token = tokenData?.token || '';
  const { data } = useQuery('onlineUsers', () => getOnlineStudents(token), {
    refetchInterval: 10_000,
  });

  return (
    <Stack alignItems={{ xs: 'end', sm: 'center' }}>
      {data ? (
        data.length > 0 && (
          <>
            <ButtonBase onClick={openModal}>
              <AvatarGroup
                componentsProps={{
                  additionalAvatar: {
                    sx: { width: avatarSize, height: avatarSize, fontSize: 10 },
                  },
                }}
                max={7}
              >
                {data.map(user => (
                  <Avatar
                    key={user.displayName}
                    alt={user.displayName}
                    src={user.profilePicture || ''}
                    sx={{ width: avatarSize, height: avatarSize }}
                  />
                ))}
              </AvatarGroup>
            </ButtonBase>
            <Typography fontWeight="bold" variant={textVariant}>
              {getDisplayText(data)}
            </Typography>

            <GenericModal isOpen={isOpen} onClose={closeModal}>
              <AllOnlineUsers data={data} />
            </GenericModal>
          </>
        )
      ) : (
        <CircularProgress />
      )}
    </Stack>
  );
}
