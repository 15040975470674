import { useProfile } from '@app/hooks/useProfile';
import {
  AddTask,
  Event,
  EventAvailable,
  LocalAtm,
  Login,
  School,
} from '@mui/icons-material';
import { Box, Grid, Hidden, Typography } from '@mui/material';
import { PointSummaryCard } from './PointSummaryCard';

export function Summary() {
  const { data } = useProfile();

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" fontWeight="bold">
        Summary
      </Typography>
      <Typography variant="caption" component="p" mt={-0.5} mb={1}>
        *Updated every minute
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4} xl>
          <PointSummaryCard
            variant="sub"
            title="Attedance"
            Icon={EventAvailable}
            points={data?.leaderboard?.lecturePoints}
          />
        </Grid>
        <Grid item xs={6} md={4} xl>
          <PointSummaryCard
            variant="sub"
            title="Events"
            Icon={Event}
            points={data?.leaderboard?.eventPoints}
          />
        </Grid>
        <Grid item xs={6} md={4} xl>
          <PointSummaryCard
            variant="sub"
            title="Daily Login"
            Icon={Login}
            points={data?.leaderboard?.loginPoints}
          />
        </Grid>
        <Grid item xs={6} md={4} xl>
          <PointSummaryCard
            variant="sub"
            title="Tutoring"
            Icon={School}
            points={data?.leaderboard?.tutorialPoints}
          />
        </Grid>
        <Grid item xs={6} md={4} xl>
          <PointSummaryCard
            variant="sub"
            title="Finacial Aid"
            Icon={LocalAtm}
            points={data?.leaderboard?.financialAidPoints}
          />
        </Grid>
        <Hidden xlUp>
          <Grid item xs={6} md={4} xl>
            <PointSummaryCard
              variant="total"
              title="Total"
              Icon={AddTask}
              points={data?.leaderboard?.score}
            />
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  );
}
