import { Results } from '@app/components/DataWrapper';
import { setHttps } from '@app/util/helpers';
import { LECTURERS_URL } from 'config';

interface LecturerImage {
  id: string;
  url: string;
  caption: string;
}

export interface LecturerData {
  id: string;
  isAdvisor: boolean;
  advisorUrl?: string;
  title: string;
  quote: string;
  favouriteBook?: string;
  favouriteBookImage?: string;
  favouriteBookUrl?: string;
  hobbies?: string;
  favouriteFood?: string;
  hometown: string;
  hometownDesc?: string;
  funFacts?: string;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    profilePicture?: string;
  };
  images: LecturerImage[];
}

export async function getLecturers(
  token: string,
): Promise<Results<LecturerData>> {
  const res = await fetch(LECTURERS_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (res.status === 200) {
    const data: Results<LecturerData> = await res.json();
    return {
      total: data.total,
      items: data.items.map(lecturer => ({
        ...lecturer,
        advisorUrl: lecturer.advisorUrl
          ? setHttps(lecturer.advisorUrl)
          : undefined,
        favouriteBookUrl: lecturer.favouriteBookUrl
          ? setHttps(lecturer.favouriteBookUrl)
          : undefined,
      })),
    };
  }
  throw new Error('Someting went wrong');
}
