import { useAuth } from '@app/hooks/useAuth';
import { useProfile } from '@app/hooks/useProfile';
import { Logout, Person } from '@mui/icons-material';
import {
  Avatar,
  ButtonBase,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const color = (theme: Theme) => ({ color: theme.palette.primary.main });

export function ProfileDropdown() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { data } = useProfile();

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    // eslint-disable-next-line unicorn/no-useless-undefined
    setAnchorEl(undefined);
  };

  const gotoProfile = () => navigate('/profile');
  const logoutClick = () => {
    handleClose();
    logout();
    navigate('/login');
  };

  return (
    <>
      <ButtonBase onClick={handleClick}>
        <Avatar
          src={data?.profilePicture}
          alt={data?.displayName}
          sx={{ height: 32, width: 32 }}
        />
      </ButtonBase>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'appbar-menu',
        }}
      >
        <MenuItem onClick={gotoProfile} sx={color}>
          <ListItemIcon>
            <Person sx={color} />
          </ListItemIcon>
          <ListItemText primary="My Profile" />
        </MenuItem>
        <MenuItem onClick={logoutClick} sx={color}>
          <ListItemIcon>
            <Logout sx={color} />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </>
  );
}
