import {
  Box,
  Card,
  CardContent,
  Stack,
  SvgIconTypeMap,
  Typography,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface Props {
  title: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  points: number;
  secondaryText: string;
}

export function PointDetailsCard({
  title,
  Icon,
  points,
  secondaryText,
}: Props) {
  return (
    <Card sx={{ bgcolor: '#FCF3F4', height: 1 }}>
      <CardContent sx={{ p: { xs: 2, md: 4 } }}>
        <Stack direction="row" alignItems="center" mb={2}>
          <Card
            elevation={0}
            sx={{ p: 3, bgcolor: '#E5A4A46B', color: '#911F27' }}
          >
            <Stack justifyContent="center" alignItems="center">
              <Icon sx={{ fontSize: 56 }} />
            </Stack>
          </Card>

          <Box ml={4}>
            <Typography variant="h6" fontWeight="bold">
              {title}
            </Typography>
            <Stack direction="row" alignItems="end">
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ color: '#911F27', mr: 1 }}
              >
                {points}
              </Typography>
              <Typography sx={{ color: '#E5A4A4' }}>Points</Typography>
            </Stack>
          </Box>
        </Stack>
        <Typography>{secondaryText}</Typography>
      </CardContent>
    </Card>
  );
}
