import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { PointHistoryTable } from './PointHistoryTable';
import { Summary } from './Summary';

export function PointsHistory() {
  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Student Portal',
            link: '/',
          },
          {
            label: 'My Points History',
            link: '/points-history',
          },
        ]}
      />
      <Summary />
      <PointHistoryTable />
    </Layout>
  );
}
