import { useAuth } from '@app/hooks/useAuth';
import { getPointConfig, PointConfigEntry } from '@app/services/pointService';
import {
  Event,
  EventAvailable,
  LocalAtm,
  Login,
  School,
} from '@mui/icons-material';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { PointDetailsCard } from './PointDetailsCard';

function processDefaultValues(defaultValues: PointConfigEntry[]) {
  const out: {
    [key: string]: Pick<PointConfigEntry, 'score' | 'id'>;
  } = {};
  defaultValues.forEach(entry => {
    out[entry.type] = {
      score: entry.score,
      id: entry.id,
    };
  });
  return out;
}

export function PointDetails() {
  const { tokenData } = useAuth();
  const token = tokenData?.token || '';

  const { data } = useQuery(['pointConfig', token], () =>
    getPointConfig({ token }),
  );

  const values = data && processDefaultValues(data.pointConfigs);

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" fontWeight="bold" mb={4}>
        SBT Points
      </Typography>
      {values ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <PointDetailsCard
              title="Lecture Attendance"
              Icon={EventAvailable}
              points={values.LECTURE_ATTENDANCE_POINTS.score}
              secondaryText={`Student can gain up to ${values.LECTURE_ATTENDANCE_WK_MAX.score} points a week with the posibility of ${values.BONUS_LECTURE_ATTENDANCE_POINTS.score} bonus points for reaching 90% overall attendance.`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PointDetailsCard
              title="Event Attendance"
              Icon={Event}
              points={values.EVENT_ATTENDANCE_POINTS.score}
              secondaryText={`Student will gain ${values.EVENT_ATTENDANCE_POINTS.score} pointer per each event attended. A student can gain upto ${values.EVENT_ATTENDANCE_SEM_MAX.score} points per semester and must submit a form via QR code.`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PointDetailsCard
              title="Tutoring Attendance"
              Icon={School}
              points={values.TUTORING_ATTENDANCE_POINTS.score}
              secondaryText={`Worth ${values.TUTORING_ATTENDANCE_WK_MAX.score} points for attending 3x weekly. Points are aquired by using the math and writing labs, and SMART tutoring tool.`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PointDetailsCard
              title="Daily Login"
              Icon={Login}
              points={values.STUDENT_PORTAL_LOGIN_POINTS.score}
              secondaryText={`Student will gain ${values.STUDENT_PORTAL_LOGIN_POINTS.score} point every time they log in. A student can gain upto ${values.STUDENT_PORTAL_SEM_MAX.score} pointer per semester`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PointDetailsCard
              title="Finacial Aid"
              Icon={LocalAtm}
              points={values.FINANCIAL_AID_BEFORE_START_POINTS.score}
              secondaryText={`Worth ${values.FINANCIAL_AID_BEFORE_START_POINTS.score} points if completed before the start of the semester. Worth ${values.FINANCIAL_AID_PHASE_1_POINTS.score} points if completed between the 1st-3rd weeks of the semester. Worth ${values.FINANCIAL_AID_PHASE_2_POINTS.score} points if completed between the 4th-6th weeks of the semester`}
            />
          </Grid>
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
}
