import { ONLINE_USERS_URL, PROFILE_UPDATE_URL, PROFILE_URL } from 'config';
import { toast } from 'react-toastify';

export interface StudentData {
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  profilePicture?: string;
  degreeAudit?: string;
  customId: string;
  id: string;
}

export interface StudentProfile extends StudentData {
  leaderboard?: {
    eventPoints: number;
    financialAidPoints: number;
    lecturePoints: number;
    loginPoints: number;
    score: number;
    semesterRank: number;
    tutorialPoints: number;
  };
}

export async function getOnlineStudents(token: string): Promise<StudentData[]> {
  const res = await fetch(ONLINE_USERS_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (res.status === 200) {
    const data = await res.json();
    return data;
  }
  throw new Error('Someting went wrong');
}

export async function getProfile(token: string): Promise<StudentProfile> {
  const res = await fetch(PROFILE_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (res.status === 200) {
    const data = await res.json();
    return data;
  }
  throw new Error('Someting went wrong');
}

export async function updateProfile({
  token,
  displayName,
  profilePicture,
}: {
  token: string;

  displayName?: string;
  profilePicture?: File;
}): Promise<void> {
  if (!displayName && !profilePicture) throw new Error('No data to update');

  const formData = new FormData();
  if (displayName) formData.append('displayName', displayName);
  if (profilePicture) formData.append('profilePicture', profilePicture);

  const res = await fetch(PROFILE_UPDATE_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (res.status === 200) {
    toast.success('Profile updated successfully');
    return;
  }
  throw new Error('Someting went wrong');
}
