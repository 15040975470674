import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';

import { Login } from './pages/Login';
import { Home } from './pages/Home';
import { EventsnWorkshops } from './pages/EventsnWorkshops';
import { MeetYourProfessors } from './pages/MeetYourProfessors';
import { SbtPoints } from './pages/SbtPoints';
import { PointsHistory } from './pages/PointsHistory';
import { Orientation } from './pages/Orientation';
import { BusinessLibrary } from './pages/BusinessLibrary';
import { FinancialLiteracy } from './pages/FinancialLiteracy';
import { MyDegreeAudit } from './pages/MyDegreeAudit';
import { StudentOpportunities } from './pages/StudentOpportunities';
import { InstitutionalResources } from './pages/InstitutionalResources';
import { LeaderBoard } from './pages/LeaderBoard';
import { Profile } from './pages/Profile';

import { useAuth } from './hooks/useAuth';
import { ForgotPassword } from './pages/ForgotPassword';
import { ResetPassword } from './pages/ResetPassword';

function AuthedRoute() {
  const { isAuth } = useAuth();
  return isAuth ? <Outlet /> : <Navigate to="/login" />;
}

function NonAuthedRoute() {
  const { isAuth } = useAuth();
  return !isAuth ? <Outlet /> : <Navigate to="/" />;
}

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<NonAuthedRoute />}>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
        </Route>
        <Route element={<AuthedRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/leaderboard" element={<LeaderBoard />} />
          <Route path="/orientation" element={<Orientation />} />
          <Route path="/professors" element={<MeetYourProfessors />} />
          <Route path="/events-n-workshops" element={<EventsnWorkshops />} />
          <Route path="/business-library" element={<BusinessLibrary />} />
          <Route path="/financial-literacy" element={<FinancialLiteracy />} />
          <Route path="/sbt-points" element={<SbtPoints />} />
          <Route path="/points-history" element={<PointsHistory />} />
          <Route path="/degree-audit" element={<MyDegreeAudit />} />
          <Route
            path="/student-opportunities"
            element={<StudentOpportunities />}
          />
          <Route
            path="/institutional-resources"
            element={<InstitutionalResources />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export { AppRoutes };
