import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { TopRankers } from '@app/components/TopRankers';
import { LeaderBoardTable } from './LeaderBoardTable';

export function LeaderBoard() {
  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Student Portal',
            link: '/',
          },
          {
            label: 'Leaderboard',
            link: '/leaderboard',
          },
        ]}
      />
      <TopRankers />
      <LeaderBoardTable />
    </Layout>
  );
}
