import { useAuth } from '@app/hooks/useAuth';
import { Logout, Person } from '@mui/icons-material';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

export function UserLinks() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const gotoProfile = () => navigate('/profile');

  const { logout } = useAuth();

  const logoutClick = () => {
    logout();
    navigate('/login');
  };

  return (
    <List disablePadding sx={{ px: 4 }}>
      <ListItemButton onClick={gotoProfile} selected={pathname === '/profile'}>
        <ListItemIcon>
          <Person color="primary" />
        </ListItemIcon>
        <ListItemText
          primary="My Profile"
          primaryTypographyProps={{ color: 'primary', fontWeight: 'bold' }}
        />
      </ListItemButton>
      <ListItemButton onClick={logoutClick}>
        <ListItemIcon>
          <Logout color="primary" />
        </ListItemIcon>
        <ListItemText
          primary="Logout"
          primaryTypographyProps={{ color: 'primary', fontWeight: 'bold' }}
        />
      </ListItemButton>
    </List>
  );
}
