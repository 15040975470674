import { useProfile } from '@app/hooks/useProfile';
import { CameraAlt } from '@mui/icons-material';
import { Card, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { DropzoneDialog } from 'react-mui-dropzone';

const useStyles = makeStyles(() => ({
  dropzone: {
    minHeight: 'unset',
    padding: 8,
    borderColor: 'gray',
    color: 'gray',
  },
}));

export function ChangeProfileImage() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const { updateProfile } = useProfile({
    onUpdateSuccess: onClose,
  });

  const onSave = (files: File[]) => {
    if (files.length > 0) {
      updateProfile({ profilePicture: files[0] });
    }
  };

  return (
    <>
      <Card sx={{ bgcolor: 'white', borderRadius: '50%' }}>
        <IconButton color="primary" size="small" onClick={onOpen}>
          <CameraAlt />
        </IconButton>
      </Card>
      <DropzoneDialog
        classes={{
          root: classes.dropzone,
        }}
        open={open}
        onSave={onSave}
        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        filesLimit={1}
        onClose={onClose}
        submitButtonText="Save"
        showPreviews
        maxFileSize={10_000_000}
      />
    </>
  );
}
