import { getLeaderboard } from '@app/services/pointService';
import { useQuery } from 'react-query';
import { useAuth } from './useAuth';

export function useLeaderboard() {
  const { tokenData } = useAuth();
  const token = tokenData?.token || '';

  const { data, isLoading } = useQuery(
    'leaderboard',
    () =>
      getLeaderboard({
        token,
        options: { page: 1, itemsPerPage: 9 },
      }),
    { refetchInterval: 60_000 },
  );

  return {
    data,
    isLoading,
    token,
  };
}
