import { Card, Box, IconButton, Typography, Slide } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useEffect, useState } from 'react';
import { useIsMobile } from '@app/hooks/useIsMobile';

//  Interface for Quote
interface IQuote {
  quote: string;
  author: string;
}

//  Interface for Props
interface Props {
  quotes: IQuote[];
  slideWidth?: string;
  autoPlay?: boolean;
  transitionDuration?: number;
}

type SlideDirection = 'left' | 'right';

export function QuotesCarousel(props: Props) {
  const {
    quotes,
    slideWidth,
    autoPlay = true,
    transitionDuration = 5000,
  } = props;
  const isMobile = useIsMobile();

  //  State variables
  const [slidePointer, setSlidePointer] = useState<number>(0);
  const [slideDirection, setSlideDirection] = useState<SlideDirection>('left');
  const [isTransitionActive, setIsTransitionActive] = useState<boolean>(true);

  //    Handle Arrow Keys
  const handleArrowKeys = (direction: SlideDirection) => {
    const oppDirection = direction === 'left' ? 'right' : 'left';
    const newIndex = (slidePointer + 1 + quotes.length) % quotes.length;

    setSlideDirection(direction);
    setIsTransitionActive(false);

    setTimeout(() => {
      setSlidePointer(newIndex);
      setSlideDirection(oppDirection);
      setIsTransitionActive(true);
    }, 300);
  };

  //    Auto Playing Slides
  useEffect(() => {
    if (autoPlay) {
      const interval = setInterval(
        () => handleArrowKeys('right'),
        transitionDuration,
      );
      return () => clearInterval(interval);
    }
    return () => {};
  });

  return (
    <Card
      sx={{
        background: 'linear-gradient(135deg, #F5AF89, #FAE7BB, #E3C7C4 )',
        padding: 2,
        borderRadius: 2,
        width: slideWidth,
      }}
      elevation={0}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <IconButton
          aria-label="back-button"
          disableRipple
          onClick={() => handleArrowKeys('left')}
        >
          <ArrowBackIosIcon fontSize="small" color="primary" />
        </IconButton>
        <Slide in={isTransitionActive} direction={slideDirection}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{ fontWeight: 'bold' }}
              color="primary"
              variant={isMobile ? 'body2' : 'h6'}
            >
              &quot;{quotes[slidePointer].quote}&quot;
            </Typography>
            <Typography
              sx={{ alignSelf: 'flex-end' }}
              color="primary"
              variant={isMobile ? 'body2' : 'h6'}
            >
              -{quotes[slidePointer].author}
            </Typography>
          </Box>
        </Slide>
        <IconButton
          aria-label="forward-button"
          disableRipple
          onClick={() => handleArrowKeys('right')}
        >
          <ArrowForwardIosIcon fontSize="small" color="primary" />
        </IconButton>
      </Box>
    </Card>
  );
}
