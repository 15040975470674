import { useProfile } from '@app/hooks/useProfile';
import { Stack, Typography } from '@mui/material';
import { AvatarWithStatus } from '../AvatarWithStatus';

export function UserInfo() {
  const { data } = useProfile();

  return (
    <Stack alignItems="center">
      <AvatarWithStatus
        src={data?.profilePicture}
        alt={data?.displayName}
        size={72}
        isOnline
      />

      <Typography variant="h6" fontWeight="bold">
        {data?.displayName}
      </Typography>
      <Typography variant="body2" color="gray" mt={-1}>
        {data?.email}
      </Typography>
    </Stack>
  );
}
