import { GenericModal, useModal } from '@app/components/GenericModal';
import { PlayArrow } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import { useIsMobile } from '@app/hooks/useIsMobile';
import {
  generateYoutubePlaylistEmbedUrl,
  generateYoutubeVideoEmbedUrl,
} from '@app/util/helpers';

interface Props {
  title: string;
  url: string;
  btnText?: string;
}

export function BusinessLibraryCard({ title, url, btnText }: Props) {
  const { isOpen, closeModal, openModal } = useModal();
  const isMobile = useIsMobile();

  const youtubeEmbedUrl =
    generateYoutubePlaylistEmbedUrl(url) || generateYoutubeVideoEmbedUrl(url);

  return (
    <Card
      sx={theme => ({
        height: 1,
        borderColor: theme.palette.primary.main,
        borderWidth: isMobile ? 1 : 2,
        borderStyle: 'solid',
      })}
    >
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
      />

      {youtubeEmbedUrl ? (
        <iframe
          style={{ pointerEvents: 'none' }}
          width="100%"
          height="300"
          src={youtubeEmbedUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      ) : (
        <Box bgcolor="#ffaaaa" py={4}>
          <Typography color="error" align="center">
            Invalid URL
          </Typography>
        </Box>
      )}

      <CardContent>
        <Stack direction="row" justifyContent="end">
          <Button
            variant="contained"
            color="primary"
            onClick={openModal}
            endIcon={<PlayArrow />}
            disabled={!youtubeEmbedUrl}
          >
            {btnText || 'Watch'}
          </Button>
        </Stack>
      </CardContent>
      <GenericModal
        isOpen={isOpen}
        onClose={closeModal}
        width={1200}
        useOnlyCloseButton
      >
        <Box height={{ xs: 400, md: 600 }} width={1}>
          {youtubeEmbedUrl && (
            <iframe
              width="100%"
              height="100%"
              src={youtubeEmbedUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </Box>
      </GenericModal>
    </Card>
  );
}
