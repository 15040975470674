import { Avatar, Badge } from '@mui/material';

interface Props {
  src?: string;
  alt?: string;
  size?: number;
  isOnline?: boolean;
}

export function AvatarWithStatus({ src, alt, size, isOnline }: Props) {
  return (
    <Badge
      variant="dot"
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiBadge-dot': {
          border: '2px solid #fff',
          height: 12,
          width: 12,
          borderRadius: '50%',
          bgcolor: isOnline ? '#1BD2A4' : 'gray',
        },
      }}
    >
      <Avatar src={src} alt={alt} sx={{ width: size, height: size }} />
    </Badge>
  );
}
