import { StudentData } from '@app/services/studentsService';
import { Search } from '@mui/icons-material';
import {
  Card,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { AvatarWithStatus } from '../AvatarWithStatus';

interface Props {
  data: StudentData[];
}
export function AllOnlineUsers({ data }: Props) {
  const [search, setSearch] = useState('');

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = e => {
    setSearch(e.target.value);
  };

  const filteredUsers = data.filter(user =>
    user.displayName.toLowerCase().includes(search.toLowerCase()),
  );

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2} mb={3}>
        <TextField
          placeholder="Search"
          variant="outlined"
          size="small"
          fullWidth
          value={search}
          onChange={handleSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Card
          sx={{
            py: 1,
            px: 2,
            flexShrink: 0,
            bgcolor: theme => theme.palette.primary.main,
            color: 'white',
          }}
        >
          <Typography fontWeight="bold">{data.length}</Typography>
        </Card>
      </Stack>

      <List disablePadding sx={{ height: 400, overflowY: 'auto' }}>
        {filteredUsers.map(user => (
          <ListItem key={user.displayName}>
            <ListItemAvatar>
              <AvatarWithStatus
                src={user.profilePicture}
                alt={user.displayName}
                isOnline
              />
            </ListItemAvatar>
            <ListItemText primary={user.displayName} secondary={user.email} />
          </ListItem>
        ))}
      </List>
    </>
  );
}
