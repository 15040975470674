import { useIsMobile } from '@app/hooks/useIsMobile';
import { useProfile } from '@app/hooks/useProfile';
import { EmojiEvents, LocalActivity } from '@mui/icons-material';
import {
  Box,
  Card,
  CardActionArea,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

function formatNumber(num: number | undefined) {
  if (!num || num < 0) return '-';
  return num;
}

interface Props {
  noAction?: boolean;
  noBg?: boolean;
}
export function PointsCard({ noAction, noBg }: Props) {
  const isMobile = useIsMobile();

  const { data } = useProfile();

  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/points-history');
  };

  const elevation = isMobile || noBg ? 0 : 1;
  const cardBgcolor = noBg ? 'transparent' : '#FFE9EB';

  return (
    <Box width={1}>
      <Card elevation={elevation} sx={{ width: 1, bgcolor: cardBgcolor }}>
        <CardActionArea
          sx={{ py: 1 }}
          disabled={noAction}
          onClick={handleClick}
        >
          <Grid container>
            <Grid item xs={6}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  color="#D76A6A"
                  mr={1}
                >
                  <LocalActivity />
                  <Typography variant="body2" mt={-0.5}>
                    Points
                  </Typography>
                </Stack>
                <Typography variant="h3" fontWeight="bold" color="primary">
                  {formatNumber(data?.leaderboard?.score)}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  color="#D76A6A"
                  mr={1}
                >
                  <EmojiEvents />
                  <Typography variant="body2" mt={-0.5}>
                    Rank
                  </Typography>
                </Stack>
                <Typography variant="h3" fontWeight="bold" color="primary">
                  {formatNumber(data?.leaderboard?.semesterRank)}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </Box>
  );
}
