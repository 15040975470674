import { DataWrapper } from '@app/components/DataWrapper';
import { ContentData, getBusinessLibrary } from '@app/services/resourceService';
import { Grid } from '@mui/material';
import { BusinessLibraryCard } from './BusinessLibraryCard';

export function ListView() {
  return (
    <DataWrapper<ContentData>
      id="business-library"
      title="Business Library"
      filterConfig={[]}
      fetchData={({ token, options }) => getBusinessLibrary({ token, options })}
      render={data => (
        <Grid container spacing={4} px={{ xs: 2, lg: 4 }}>
          {data.map(x => (
            <Grid item xs={12} md={12} lg={6} xl={4} key={x.id}>
              <BusinessLibraryCard
                title={x.title}
                url={x.url}
                btnText={x.btnText}
              />
            </Grid>
          ))}
        </Grid>
      )}
    />
  );
}
