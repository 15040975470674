import moment from 'moment';
import { toast } from 'react-toastify';

export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  return moment(date).format('MMMM Do YYYY');
}

export function formatTime(date: string): string {
  const temp = moment(date);
  return moment()
    .set({
      hours: temp.hours(),
      minutes: temp.minutes(),
    })
    .format('h:mm a');
}

export function formatDateTime(dateTimeString: string): string {
  const date = new Date(dateTimeString);
  return moment(date).format('MMMM Do YYYY, h:mm a');
}

interface ApiCallOptions<D> {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  url: string;
  token?: string;
  json?: D;
  formData?: FormData;
  successMessage?: string;
}
export async function apiCall<T, D>({
  method = 'POST',
  url,
  token,
  json,
  formData,
  successMessage,
}: ApiCallOptions<D>): Promise<T> {
  const headers = new Headers();
  if (token) headers.append('Authorization', `Bearer ${token}`);
  if (json) headers.append('Content-Type', 'application/json');

  // eslint-disable-next-line unicorn/no-null
  let body: BodyInit | null = null;
  if (json) body = JSON.stringify(json);
  if (formData) body = formData;

  const res = await fetch(url, { method, headers, body });
  if (res.status === 200) {
    const data = await res.json();
    if (successMessage) toast.success(successMessage);
    return data;
  }
  if (res.status === 400) {
    const err = await res.json();
    throw new Error(err as string);
  }
  throw new Error('Someting went wrong');
}

export async function apiCallVoid<D>({
  method = 'POST',
  url,
  token,
  json,
  formData,
  successMessage,
}: ApiCallOptions<D>): Promise<void> {
  const headers = new Headers();
  if (token) headers.append('Authorization', `Bearer ${token}`);
  if (json) headers.append('Content-Type', 'application/json');

  // eslint-disable-next-line unicorn/no-null
  let body: BodyInit | null = null;
  if (json) body = JSON.stringify(json);
  if (formData) body = formData;

  const res = await fetch(url, { method, headers, body });
  if (res.status === 200) {
    if (successMessage) toast.success(successMessage);
    return;
  }
  if (res.status === 400) {
    const err = await res.json();
    throw new Error(err as string);
  }
  throw new Error('Someting went wrong');
}

export function getFormData<
  T extends Record<keyof T, string | Blob | undefined>,
>(form: T): FormData {
  const formData = new FormData();
  Object.keys(form).forEach(key => {
    const value = form[key as keyof T];
    if (value) {
      formData.append(key, value);
    }
  });
  return formData;
}

export function setHttps(link: string) {
  if (link.search(/^https?:\/\//) === -1) {
    return `https://${link}`;
  }
  return link;
}

export function generateYoutubePlaylistEmbedUrl(url: string) {
  const splited = url.split('list=');
  return splited.length > 1
    ? `https://www.youtube.com/embed/videoseries?list=${splited[1]}`
    : undefined;
}

export function generateYoutubeVideoEmbedUrl(url: string) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11
    ? `https://www.youtube.com/embed/${match[2]}`
    : undefined;
}
