import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  ButtonBase,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import { Menu } from '@mui/icons-material';

import logoImg from '@assets/logo.png';
import { useIsMobile } from '@app/hooks/useIsMobile';
import { UserInfo } from './UserInfo';
import { OnlineUsers } from '../OnlineUsers';
import { NavLinks } from './NavLinks';
import { PointsCard } from '../PointsCard';
import { NavLeaderboard } from './NavLeaderboard';
import { ProfileDropdown } from './ProfileDropdown';
import { UserLinks } from './UserLinks';
import { Footer } from './Footer';
import { QrScanButton } from '../QrScanButton/QrScanButton';
import { CheckExpiredToken } from '../CheckExpiredToken';

const drawerWidth = 380;

const zIndex = (theme: Theme) => theme.zIndex.drawer + 1;

interface Props {
  children?: React.ReactNode;
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export function Layout(props: Props) {
  const { window, children } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const toHome = () => navigate('/');

  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useIsMobile();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Stack justifyContent="space-between" minHeight="100vh">
      <div>
        <Toolbar />
        <Divider />
        <Box pt={3} />
        {!isMobile && (
          <>
            <UserInfo />
            <Box mb={3} />
            <Box mb={3} px={4}>
              <PointsCard />
            </Box>
            <OnlineUsers />
            <Divider variant="middle" sx={{ borderStyle: 'dashed', my: 3 }} />
          </>
        )}
        {isMobile || pathname !== '/' ? <NavLinks /> : <NavLeaderboard />}
      </div>
      {isMobile && (
        <Box pb={4}>
          <UserLinks />
        </Box>
      )}
    </Stack>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <CheckExpiredToken>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" sx={{ zIndex }}>
          <Toolbar>
            <Stack
              component={ButtonBase}
              direction="row"
              alignItems="center"
              onClick={toHome}
              p={1}
              m={-1}
            >
              <Box
                component="img"
                sx={{ height: 40, objectFit: 'contain' }}
                src={logoImg}
                alt="Student Portal"
              />
              <Typography variant="h6" noWrap component="div">
                Student Portal
              </Typography>
            </Stack>
            <Box sx={{ ml: 'auto', display: { xs: 'none', sm: 'block' } }}>
              <ProfileDropdown />
            </Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ ml: 'auto', display: { sm: 'none' } }}
            >
              <Menu />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="drawer"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                borderBottomRightRadius: 16,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            minHeight: '100vh',
          }}
        >
          <Toolbar />

          <Box sx={{ flexGrow: 1, p: isMobile ? 2 : 4 }}>{children}</Box>
          <QrScanButton />
          <Footer />
        </Box>
      </Box>
    </CheckExpiredToken>
  );
}
