import { AvatarWithPlace } from '@app/components/AvatarWithPlace';
import { useIsMobile } from '@app/hooks/useIsMobile';
import { Card, CardContent, Stack, Typography } from '@mui/material';

interface Props {
  rank: number;
  name: string;
  avatar?: string;
  points: number;
}

const bgcolors: {
  [key: number]: string;
} = {
  1: '#F9F3EA',
  2: '#F2F2FB',
  3: '#FDF6F0',
};

export function TopRankCard({ rank, name, avatar, points }: Props) {
  const isMobile = useIsMobile();

  const elevation = isMobile ? 0 : 1;
  const bgcolor = isMobile ? 'transparent' : bgcolors[rank];
  const avatarSize = isMobile ? 48 : 80;
  const placeSize = isMobile ? 28 : 38;
  const nameVariant = isMobile ? 'body2' : 'h6';

  return (
    <Card elevation={elevation} sx={{ bgcolor, height: 1 }}>
      <CardContent sx={{ p: { xs: 0, sm: 2 } }}>
        <Stack alignItems="center">
          <AvatarWithPlace
            src={avatar}
            alt={name}
            size={avatarSize}
            place={rank}
            placeSize={placeSize}
          />
          <Typography
            variant={nameVariant}
            fontWeight="bold"
            textAlign="center"
            sx={{ lineHeight: 1.2, mt: { xs: 1, sm: 2 } }}
          >
            {name}
          </Typography>
          {!isMobile && (
            <Typography
              variant="h6"
              color="primary"
              fontWeight="bold"
              sx={{ lineHeight: 1 }}
            >
              {points}
            </Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
