import { useIsMobile } from '@app/hooks/useIsMobile';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Stack,
  SvgIconTypeMap,
  Typography,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useNavigate } from 'react-router-dom';

interface Props {
  title: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  link: string;
  disabled: boolean;
  description: string;
  color: string;
}

export function HomeCard({
  title,
  Icon,
  link,
  disabled,
  description,
  color,
}: Props) {
  const isMobile = useIsMobile();

  const navigate = useNavigate();
  const onClick = () => navigate(link);

  return (
    <Card
      elevation={0}
      sx={theme => ({
        borderColor: color,
        color: theme.palette.primary.main,
        borderWidth: isMobile ? 1 : 2,
        borderStyle: 'solid',
        height: 1,
        pointerEvents: disabled ? 'none' : 'initial',
        opacity: disabled ? 0.3 : 1,
      })}
      onClick={onClick}
    >
      <CardActionArea sx={{ height: 1 }}>
        {isMobile ? (
          <Stack alignItems="center" sx={{ p: 2 }}>
            <Icon sx={{ color }} />
            <Typography variant="body2" fontWeight="bold" textAlign="center">
              {title}
            </Typography>
          </Stack>
        ) : (
          <>
            <CardHeader
              title={title}
              subheader="Learn more →"
              titleTypographyProps={{
                variant: 'h6',
                fontWeight: 'bold',
              }}
              subheaderTypographyProps={{
                color: 'primary',
                sx: { textDecoration: 'underline' },
              }}
              avatar={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: color,
                    color: 'white',
                    borderRadius: '50%',
                    height: 48,
                    width: 48,
                  }}
                >
                  <Icon />
                </Box>
              }
            />
            <CardContent sx={{ pt: 0 }}>
              <Typography>{description}</Typography>
            </CardContent>
          </>
        )}
      </CardActionArea>
    </Card>
  );
}
