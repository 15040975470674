/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { BrowserQRCodeReader } from '@zxing/browser';
import React, { useEffect } from 'react';
import { MenuItem, Stack, TextField, Typography } from '@mui/material';

const cameraFeedElId = 'video-id';
const scanner = new BrowserQRCodeReader();

interface Props {
  onData: (data: string) => void;
}
export function QrCodeScanner({ onData }: Props) {
  const [allDevices, setAllDevices] = React.useState<MediaDeviceInfo[]>([]);
  const [deviceId, setDeviceId] = React.useState<string>('');
  const [hasPermission, setHasPermission] = React.useState<boolean>(false);
  const [err, setErr] = React.useState<string>('');
  const [deviceErr, setDeviceErr] = React.useState<string>('');

  const onDeviceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErr('');
    setDeviceErr('');
    setDeviceId(event.target.value);
    localStorage.setItem('deviceId', event.target.value);
  };

  useEffect(() => {
    const getPermission = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {},
        });
        setHasPermission(true);
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        setErr(`${error}`);
      }
    };
    getPermission();
  }, []);

  useEffect(() => {
    const capture = async () => {
      if (hasPermission && deviceId) {
        try {
          const res = await scanner.decodeOnceFromVideoDevice(
            deviceId,
            cameraFeedElId,
          );
          onData(res.getText());
        } catch (error) {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          setDeviceErr(`${error}`);
        }
      }
    };
    capture();
    return () => BrowserQRCodeReader.releaseAllStreams();
  }, [deviceId, hasPermission, onData]);

  useEffect(() => {
    if (hasPermission) {
      BrowserQRCodeReader.listVideoInputDevices().then(devices => {
        setAllDevices(devices);
        if (devices.length > 0) {
          const prevDevice = localStorage.getItem('deviceId');
          if (prevDevice && devices.some(d => d.deviceId === prevDevice)) {
            setDeviceId(prevDevice);
          } else {
            setDeviceId(devices[0].deviceId);
            localStorage.setItem('deviceId', devices[0].deviceId);
          }
        } else {
          setErr('No devices found.');
        }
      });
    }
  }, [hasPermission]);

  if (err || !hasPermission) {
    return (
      <Stack
        width={1}
        height={300}
        bgcolor="#FFE9EB"
        justifyContent="center"
        alignItems="center"
        gap={1}
      >
        <Typography fontWeight="bold" align="center">
          {err || 'Waiting for camera permissions...'}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack gap={2} alignItems="center">
      <video
        id={cameraFeedElId}
        style={{
          width: 'auto',
          height: 300,
          maxWidth: '100%',
          display: deviceErr ? 'none' : 'block',
        }}
      />
      {deviceErr && (
        <Stack
          width={1}
          height={300}
          bgcolor="#FFE9EB"
          justifyContent="center"
          alignItems="center"
        >
          <Typography fontWeight="bold" align="center">
            {deviceErr}
          </Typography>
        </Stack>
      )}
      <TextField
        label="Select Camera"
        value={deviceId}
        onChange={onDeviceChange}
        select
      >
        {allDevices.map(camera => (
          <MenuItem key={camera.deviceId} value={camera.deviceId}>
            {camera.label}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  );
}
