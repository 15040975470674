import {
  Button,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Modal,
  Stack,
} from '@mui/material';

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  useOnlyCloseButton?: boolean;
  title?: string;
  children: React.ReactNode;
  actions?: React.ReactNode[];
  width?: string | number;
  removeCloseButton?: boolean;
}
export function GenericModal({
  isOpen,
  onClose,
  useOnlyCloseButton,
  title,
  children,
  actions,
  width,
  removeCloseButton,
}: Props) {
  return (
    <Modal open={isOpen} onClose={useOnlyCloseButton ? undefined : onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: width || 400,
          maxWidth: 'calc(100% - 32px)',
          p: '0!important',
          overflow: 'auto',
          maxHeight: 'calc(100vh - 32px)',
        }}
      >
        <Card>
          {title && (
            <CardHeader
              title={title}
              titleTypographyProps={{
                variant: 'h6',
                fontWeight: 'bold',
              }}
              sx={{ pb: 0 }}
            />
          )}
          <CardContent>{children}</CardContent>
          <CardActions>
            <Stack direction="row" justifyContent="end" width={1} gap={2}>
              {!removeCloseButton && (
                <Button variant="outlined" color="primary" onClick={onClose}>
                  Close
                </Button>
              )}
              {actions}
            </Stack>
          </CardActions>
        </Card>
      </Box>
    </Modal>
  );
}
