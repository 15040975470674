import { DataWrapper } from '@app/components/DataWrapper';
import { GenericTable, TableHeader } from '@app/components/GenericTable';
import { useIsMobile } from '@app/hooks/useIsMobile';
import {
  getPointHistory,
  PointHistoryEntry,
  PointType,
} from '@app/services/pointService';
import { formatDateTime } from '@app/util/helpers';

function getPointTitle(entry: PointHistoryEntry): string {
  switch (entry.source.type) {
    case 'LECTURE_ATTENDANCE':
      return entry.source.lecture?.name || 'Lecture';
    case 'BONUS_LECTURE_ATTENDANCE':
      return 'Attendance Bonus';
    case 'TUTORING_ATTENDANCE':
      return entry.source.tutoring?.name || 'Tutoring';
    case 'EVENT_ATTENDANCE':
      return entry.source.event?.name || 'Event';
    case 'FINANCIAL_AID':
      return 'Financial Aid';
    case 'STUDENT_PORTAL_LOGIN':
      return 'Student Portal Login';
    default:
      return 'Unknown';
  }
}

function getPointType(type: PointType): string {
  switch (type) {
    case 'LECTURE_ATTENDANCE':
      return 'Attendance';
    case 'BONUS_LECTURE_ATTENDANCE':
      return 'Attendance Bonus';
    case 'TUTORING_ATTENDANCE':
      return 'Tutoring';
    case 'EVENT_ATTENDANCE':
      return 'Event';
    case 'FINANCIAL_AID':
      return 'Financial Aid';
    case 'STUDENT_PORTAL_LOGIN':
      return 'Daily Login';
    default:
      return 'Unknown';
  }
}

const headers: TableHeader<PointHistoryEntry>[] = [
  {
    headerLabel: 'Title',
    isSortable: false,
    key: 'id',
    renderCell: row => getPointTitle(row),
  },
  {
    headerLabel: 'Claimed On',
    isSortable: true,
    key: 'timestamp',
    renderCell: row => formatDateTime(row.timestamp),
  },
  {
    headerLabel: 'Point Catergory',
    isSortable: false,
    key: 'source.type',
    renderCell: row => getPointType(row.source.type),
  },
];

const mobileHeaders: TableHeader<PointHistoryEntry>[] = headers;

export function PointHistoryTable() {
  const isMobile = useIsMobile();
  return (
    <DataWrapper<PointHistoryEntry>
      id="point-history"
      title="My Points History"
      filterConfig={[
        {
          field: 'source.type',
          label: 'Point Category',
          value: '',
          type: 'equals',
          options: [
            { label: 'All', value: '' },
            { label: 'Attendance Bonus', value: 'BONUS_LECTURE_ATTENDANCE' },
            { label: 'Lecture', value: 'LECTURE_ATTENDANCE' },
            { label: 'Tutoring', value: 'TUTORING_ATTENDANCE' },
            { label: 'Event', value: 'EVENT_ATTENDANCE' },
            { label: 'Financial Aid', value: 'FINANCIAL_AID' },
            { label: 'Daily Login', value: 'STUDENT_PORTAL_LOGIN' },
          ],
        },
      ]}
      fetchData={getPointHistory}
      render={(data, sortBy, setSortBy) => (
        <GenericTable
          tableHeaders={isMobile ? mobileHeaders : headers}
          data={data}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
      )}
    />
  );
}
