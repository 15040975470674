import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { ListView } from './ListView';

export function FinancialLiteracy() {
  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Student Portal',
            link: '/',
          },
          {
            label: 'Financial Literacy',
            link: '/financial-literacy',
          },
        ]}
      />
      <ListView />
    </Layout>
  );
}
