import { DataWrapper } from '@app/components/DataWrapper';
import { EventData, getUpcomingEvents } from '@app/services/eventService';
import { formatDate, formatTime } from '@app/util/helpers';
import { Grid } from '@mui/material';
import { EventCard } from './EventCard';

export function EventsListView() {
  return (
    <DataWrapper<EventData>
      id="events"
      title="Events & Workshops"
      filterConfig={[
        {
          field: 'name',
          label: 'Search by Name',
          value: '',
          type: 'contains',
          isCaseInsensitive: true,
        },
      ]}
      fetchData={getUpcomingEvents}
      render={data => (
        <Grid container spacing={2} px={{ xs: 2, lg: 4 }}>
          {data.map(event => (
            <Grid item xs={12} md={6} lg={6} xl={4} key={event.id}>
              <EventCard
                name={event.name}
                date={formatDate(event.startDate)}
                description={event.description}
                image={event.image || ''}
                start={formatTime(event.startDate)}
                end={formatTime(event.endDate)}
                venue={event.venue}
                link={event.link}
              />
            </Grid>
          ))}
        </Grid>
      )}
    />
  );
}
