import {
  Avatar,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
  CardActions,
  IconButton,
  Link,
  CardActionArea,
  Button,
} from '@mui/material';
import { useIsMobile } from '@app/hooks/useIsMobile';
import { OpenInNew, Mail } from '@mui/icons-material';
import { GenericModal, useModal } from '@app/components/GenericModal';
import { LecturerData } from '@app/services/lecturerService';
import { LecturerDetailsCard } from './LecturerDetailsCard';

interface Props {
  lecturer: LecturerData;
}

export function LecturerListCard(props: Props) {
  const { lecturer } = props;
  const name = `${lecturer.title}. ${lecturer.user.firstName} ${lecturer.user.lastName}`;

  const { isOpen, closeModal, openModal } = useModal();
  const isMobile = useIsMobile();

  return (
    <>
      <Card
        sx={theme => ({
          backgroundColor: '#F9F9FA',
          height: 1,
          display: 'flex',
          flexDirection: 'column',
          borderColor: theme.palette.primary.main,
          borderWidth: isMobile ? 1 : 2,
          borderStyle: 'solid',
        })}
      >
        <CardActionArea onClick={openModal} sx={{ flexGrow: 1 }}>
          <CardContent
            sx={{
              height: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
            }}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar
                alt="lecturer-card"
                src={lecturer.user.profilePicture}
                sx={{ width: 72, height: 72 }}
              />
              <Stack direction="column" justifyContent="left">
                <Typography variant="body1" color="primary" fontWeight="bold">
                  {name}
                </Typography>
                <Typography variant="body2">{`From ${lecturer.hometown}`}</Typography>
              </Stack>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1" my={2}>
              {lecturer.quote}
            </Typography>
          </CardContent>
        </CardActionArea>

        <CardActions
          sx={{
            backgroundColor: '#FCF3F4',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width={1}
          >
            <IconButton aria-label="email" disableRipple>
              <Link
                href={`mailto:${lecturer.user.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Mail color="primary" />
              </Link>
            </IconButton>
            {lecturer.isAdvisor &&
              (lecturer.advisorUrl ? (
                <Link
                  href={lecturer.advisorUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ textDecoration: 'none' }}
                >
                  <Button
                    variant="contained"
                    color="warning"
                    size="small"
                    disableElevation
                    sx={{
                      textTransform: 'none',
                      py: 0,
                      px: 1,
                    }}
                  >
                    Advisor <OpenInNew sx={{ fontSize: '.9rem', ml: 1 }} />
                  </Button>
                </Link>
              ) : (
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disableElevation
                  sx={{
                    textTransform: 'none',
                    py: 0,
                    px: 1,
                  }}
                >
                  Advisor
                </Button>
              ))}
          </Stack>
        </CardActions>
      </Card>
      <GenericModal isOpen={isOpen} onClose={closeModal} width={800}>
        <LecturerDetailsCard lecturer={lecturer} />
      </GenericModal>
    </>
  );
}
