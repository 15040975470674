import { OpenInNew } from '@mui/icons-material';
import { Card, CardContent, CardMedia, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useIsMobile } from '@app/hooks/useIsMobile';

interface Props {
  name: string;
  date: string;
  description: string;
  image: string;
  start: string;
  end: string;
  venue: string;
  link?: string;
}

export function EventCard({
  name,
  date,
  description,
  image,
  start,
  end,
  venue,
  link,
}: Props) {
  const isMobile = useIsMobile();
  return (
    <Card
      sx={theme => ({
        position: 'relative',
        height: 1,
        borderColor: theme.palette.primary.main,
        borderWidth: isMobile ? 1 : 2,
        borderStyle: 'solid',
      })}
    >
      <CardMedia
        component="img"
        height="150"
        src={image}
        alt={name}
        sx={{ position: 'relative' }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 24,
          width: 60,
          height: 100,
          bgcolor: '#FFE9EB',
          clipPath: 'polygon(0 0, 100% 0%, 100% 100%, 50% 75%, 0 100%)',
          boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.9)',
        }}
      >
        <Typography
          variant="h6"
          color="primary"
          fontWeight="bold"
          align="center"
          mt={2}
        >
          05
        </Typography>
        <Typography variant="body2" color="primary" align="center">
          Points
        </Typography>
      </Box>
      <CardContent>
        <Typography variant="h6" fontWeight="bold" color="primary">
          {name}
        </Typography>
        <Typography fontWeight="bold" mt={-0.5} mb={1}>
          {date} | {start} - {end}
        </Typography>

        <Typography fontWeight="bold" mb={1}>
          {venue}
          {link && (
            <>
              {' | '}
              <Link href={link} target="_blank" rel="noopener noreferrer">
                Link to event <OpenInNew sx={{ fontSize: '.9rem' }} />
              </Link>
            </>
          )}
        </Typography>
        <Typography lineHeight={1.2}>{description}</Typography>
      </CardContent>
    </Card>
  );
}
