import { DataQueryOptions, Results } from '@app/components/DataWrapper';
import { apiCall, setHttps } from '@app/util/helpers';
import { ALL_BUSINESS_LIBRARY_URL, ALL_FINACIAL_LITERACY_URL } from 'config';

export interface ContentData {
  id: string;
  url: string;
  image?: string;
  title: string;
  description: string;
  youtubeUrl?: string;
  btnText?: string;
}
interface GetOptoins {
  token: string;
  options: DataQueryOptions;
}

export async function getFinacilaLiteracy({
  token,
  options,
}: GetOptoins): Promise<Results<ContentData>> {
  const res: Results<ContentData> = await apiCall({
    url: ALL_FINACIAL_LITERACY_URL,
    method: 'POST',
    token,
    json: options,
  });
  return {
    items: res.items.map(item => ({
      ...item,
      url: setHttps(item.url),
    })),
    total: res.total,
  };
}

export async function getBusinessLibrary({
  token,
  options,
}: GetOptoins): Promise<Results<ContentData>> {
  return apiCall({
    url: ALL_BUSINESS_LIBRARY_URL,
    method: 'POST',
    token,
    json: options,
  });
}
