export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

export const LOGIN_URL = `${API_URL}/api-auth/login/portal/`;
export const CHANGE_PASSWORD_URL = `${API_URL}/api-auth/change-password/`;
export const FORGOT_PASSWORD_URL = `${API_URL}/api-auth/forgot-password/`;
export const RESET_PASSWORD_URL = `${API_URL}/api-auth/reset-password/`;

export const PROFILE_URL = `${API_URL}/student/profile/`;
export const PROFILE_UPDATE_URL = `${API_URL}/user/update/`;
export const ONLINE_USERS_URL = `${API_URL}/student/online/`;
export const LEADERBOARD_URL = `${API_URL}/point/leaderboard/`;
export const POINT_HISTORY_URL = `${API_URL}/point/my-history/`;
export const CLAIM_POINTS_BY_QR_URL = `${API_URL}/point/claim-by-qr/`;
export const UPCOMING_EVENTS_URL = `${API_URL}/event/upcoming/`;
export const LECTURERS_URL = `${API_URL}/lecturer/all/`;
export const POINT_CONFIG_URL = `${API_URL}/point/config/`;

export const ALL_FINACIAL_LITERACY_URL = `${API_URL}/resource/finacial-literacy/all/`;
export const ALL_BUSINESS_LIBRARY_URL = `${API_URL}/resource/business-library/all/`;

export const SYSTEM_CONFIG_URL = `${API_URL}/system-config/`;
