import { OpenInNew } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import { useIsMobile } from '@app/hooks/useIsMobile';
import {
  generateYoutubePlaylistEmbedUrl,
  generateYoutubeVideoEmbedUrl,
} from '@app/util/helpers';

interface Props {
  title: string;
  description: string;
  image?: string;
  url: string;
  youtubeUrl?: string;
  btnText?: string;
}

export function ResourceCard({
  title,
  description,
  image,
  youtubeUrl,
  url,
  btnText,
}: Props) {
  const youtubeEmbedUrl = youtubeUrl
    ? generateYoutubeVideoEmbedUrl(youtubeUrl) ||
      generateYoutubePlaylistEmbedUrl(youtubeUrl)
    : undefined;

  const isMobile = useIsMobile();

  return (
    <Card
      sx={theme => ({
        height: 1,
        borderColor: theme.palette.primary.main,
        borderWidth: isMobile ? 1 : 2,
        borderStyle: 'solid',
      })}
    >
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
      />

      {youtubeEmbedUrl && (
        <iframe
          width="100%"
          height="300"
          src={youtubeEmbedUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
      {!youtubeEmbedUrl && image && (
        <Box
          component="img"
          src={image}
          alt={title}
          sx={{ width: 1, height: { xs: 200, lg: 300 }, objectFit: 'contain' }}
        />
      )}
      <CardContent>
        <Typography mb={2}>{description}</Typography>
        <Stack direction="row" justifyContent="end">
          <Button
            variant="contained"
            color="primary"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            endIcon={<OpenInNew />}
          >
            {btnText || 'Learn More'}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}
